import React, { FunctionComponent } from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";

import classnames from "classnames";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Content from "../components/content";
import HtmlInfoText from "../components/html-info-text";

import HomeIcon from "../images/icon-home.svg";
import CallIcon from "../images/icon-call.svg";
import MailIcon from "../images/icon-mail.svg";
import MapPin from "../images/map-pin.svg";
import InfoIcon from "../images/info.svg";
import classes from "./contact.module.css";
import { extractHtml } from "../util";
import Hero from "../components/hero";
import PageTitle from "../components/page-title";

const query = graphql`
    query {
        text: file(name: { eq: "contact" }) {
            childMarkdownRemark {
                html
            }
        }
    }
`;

interface InformationContainerProps {
    icon: React.ReactNode;
    fill?: boolean;
}

const InformationContainer: FunctionComponent<InformationContainerProps> = ({
    icon,
    children,
    fill = true,
}) => (
    <div className={classes.infoContainer}>
        <div
            className={classnames(classes.iconContainer, {
                [classes.fill]: fill,
            })}
        >
            {icon}
        </div>

        <div className={classes.info}>{children}</div>
    </div>
);

const Address = () => (
    <InformationContainer icon={<HomeIcon />}>
        <p>Oranjehof 2</p>
        <p>2161 KB Lisse</p>
    </InformationContainer>
);

const ContactDetails = () => (
    <div>

    </div>
);

const ContactPage: FunctionComponent<PageProps> = ({ location }) => {
    const data = useStaticQuery(query);
    const { text } = extractHtml(data);

    return (
        <Layout currentPath={location.pathname} contactDetailsInFooter={false}>
            <SEO title="Contact" description="De contact informatie voor Praktijk de Leersprong, met adres, email, sociale media, en KvK gegevens." />
            <Hero />
            <PageTitle>Contact</PageTitle>
            <Content>
                <div className={classes.contactContainer}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Address />
                        <a
                            href="https://goo.gl/maps/qaKBR9NWbWYpMriy5"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.mapsButton}
                        >
                            <MapPin />
                        </a>
                    </div>
                    <InformationContainer icon={<CallIcon />}>
                        <a href="tel:+31641535354">06-41535354</a>
                    </InformationContainer>
                    <InformationContainer icon={<MailIcon />}>
                        <a href="mailto:info@praktijkdeleersprong.nl">
                            info@praktijkdeleersprong.nl
                        </a>
                    </InformationContainer>

                    <InformationContainer icon={<InfoIcon style={{fill: 'none'}} />}>
                        <p>KVK-nummer: 77944925</p>
                        <p>BTW-nummer: NL003264019B61</p>
                    </InformationContainer>
                </div>
                <HtmlInfoText html={text} />
            </Content>
        </Layout>
    );
};

export default ContactPage;
